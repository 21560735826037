<template>
    <div class="fondo-degradado-recover"></div>
    <div class="contenedor-recoverSucces d-flex align-items-center justify-content-center flex-column">
        <h1 class="titulo-recoverSucces">¡Estas a pocos pasos!</h1>
        <h2 class="subtitulo-recoverSucces">Cambia tu contraseña.</h2>
        <form class="form-recoverSucces d-flex flex-column" v-on:submit.prevent>
            <label for="con1" class="label">Nueva contraseña</label>
            <input type="password" id="con1" class="input" v-model="password" />
            <label for="con2" class="label">Confimar contraseña</label>
            <input type="password" id="con2" class="input" v-model="password2" />
            <div class="d-flex flex-row justify-content-center">
                <router-link to="/register" class="link-recoverSucces">Registrarme</router-link>
                <router-link to="/login" class="link-recoverSucces">Login</router-link>
            </div>
            <button class="button-recoverSucces d-flex justify-content-center align-items-center" @click="cambiarCon">Listo</button>
        </form>  
        <div class="error-login d-flex justify-content-center align-items-center" v-if="this.stateLogin.success === 'error'">
            <div><p>{{mensaje}}</p></div>
        </div>  
        <div class="correcto-login d-flex justify-content-center align-items-center" v-if="!this.isSesionActive &&this.stateLogin.success === 'ok'">
            <div><p>{{mensaje}}</p></div>
        </div>   
    </div>
</template>

<script>
    import {mapState, mapActions} from "vuex"

    export default {
        name: "RecoverSucces",
        data(){
            return{
                //control de respuestas de la api
                mensaje:'',

                //passwords inputs
                password:'',
                password2:''
            }
        },
        methods:{
            ...mapActions([
                "recoverSucces",
                "login",
                "sesionActive",
                "sesionInactive",
                "clearStateLogin"
            ]),

            //funcion de cambiar passwrds

            async cambiarCon() {

                if(this.password.length > 30){
                    //completa los campos
                    this.swift('Contraseña no puede exceder los 30 caracteres',1)
                    return
                }

                if(this.password !== '' && this.password2 !== ''){
                    if(this.password === this.password2){
                        localStorage.setItem("passGes", this.password)
                        localStorage.setItem("token", this.$route.params.id)
                        await this.recoverSuccess()

                        if(this.stateLogin.success === "ok"){
                            //password cambiada
                            this.swift('contraseña cambiada con exito', 2, '/login')
                        }
                        if(this.stateLogin.message === "Error en validación"){
                            //se espiro la url
                            this.swift('tiempo excedido para cambio de contraseña',1)
                        }
                    }else{
                        //las contraseñas no coinciden
                        this.swift('La contraseñas no coinciden',1)
                    }
                }else{
                    //completa los campos
                    this.swift('Completa los campos',1)
                }
            },

        },
        computed:{
        ...mapState(["token", "server", "stateLogin", "isSesionActive"]),
        }
    }
</script>

<style scoped>
    .contenedor-recoverSucces{
        position: relative;
        height: 600px;
        width: 100%;
        overflow: hidden;
    }
    .fondo-degradado-recover{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        background: linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%), linear-gradient(248.49deg, rgba(255, 255, 255, 0) 41.48%, #297F87 100.24%);
        z-index: -10;
        height: 100vh;
    }
    .titulo-recoverSucces{
        font-family: Rubik;
        font-weight: bold;
        margin-top:80px ;
        font-size: 55px;
    }
    .error-login{
        margin: 1.5em 0em 1.5em 0em;
        padding-top: 1em;
        width: 700px;
        background-color: rgb(128, 18, 22,0.75);
        color: white;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
    }
    .correcto-login{
        margin: 1.5em 0em 1.5em 0em;
        padding-top: 1em;
        width: 700px;
        background-color: rgb(11, 114, 40,0.75);
        color: white;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
    }
    .subtitulo-recoverSucces{
        font-family: Rubik;
        margin-top:0px ;
        font-weight: bold;
        font-size: 25px;
    }
    .form-recoverSucces{
        width: 50%;
    }
    .label{
        font-family: Rubik;
        margin-top: 25px;   
        margin-left: 25%;
        font-weight:bold ;
    }
    .input{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 50%;
        margin-left: 25%;
        padding-left: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .button-google{
        border-radius:4px;
        margin-top: 45px;  
        width: 50%;
        margin-left: 25%;
        border: #6464646b 2px solid;
        height: 30px;
        background: white;
    }
    .logo-google{
        width: 20px;
        height: 20px;
        background-image:url(../../assets/pictures/google.svg) ;
        background-repeat: no-repeat;
        background-size: 20px 20px;
    }
    .texto-google{
        margin-left: 13px;
        height: 10px;
        font-family: Rubik;
        font-weight:bold ;
        font-size: 16px;
    }
    .link-recoverSucces{
        width: 150px;
        text-align: center;
        font-family: Rubik;
        font-weight:bold ;
        font-size: 16px;
        margin-top: 25px;
        color: #297F87;
        text-decoration: none;
    }
    .recupera-recoverSucces{
        margin-top: 7.5px;
        width: 100%;
        text-align: center;
        font-family: Rubik;
        font-weight:bold ;
        font-size: 16px;
    }
    .button-recoverSucces{
        width: 75%;
        height: 40px;
        border:none;
        border-radius:10px;
        background: #297F87;
        color: white;
        text-align: center;
        font-family: Rubik;
        font-size:20px ;
        margin-left: 12.5%;
        margin-top: 50px;
    }
</style>